import { AppSettings } from 'utils/app-settings';
import { Aurelia, autoinject, computedFrom, singleton } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from "aurelia-event-aggregator";
import { HttpService } from 'utils/http-service';
import { HttpClient } from 'aurelia-fetch-client';
import environment from 'environment';


export class AuthData {
  session: string = "";
  username: string = "";
  isSuperUser: boolean = false;
  inSuperGroup: boolean = false;
  tabTrabajadoresActivo: boolean = false;
  tabMaquinasActivo: boolean = false;
  tabCdtActivo: boolean = false;
  tabCdtDocActivo: boolean = false;
  tabDocEmpresa: boolean = false;
  subirDoc: boolean = false;
  doc_cad_edit: boolean = false;
  modificarDoc: boolean = false;
  modificarFechasDoc: boolean = false;
  modificarDescDoc: boolean = false;
  modificarAptoMedicoDoc: boolean = false;
  modificarCalidadDoc : boolean = false;
  descargarDoc: boolean = false;
  descargarMasivaDoc: boolean = false;
  modificarTrab: boolean = false;
  altaTrab: boolean = false;
  asignaTrabCdt: boolean = false;
  desasignaTrabCdt: boolean = false;
  editar_trab_cdt: boolean = false;
  regeneraDoc: boolean = false;
  validaDoc: boolean = false;
  revisaDoc: boolean = false;
  subidosDoc: boolean = false;
  ver_caducados: boolean = false;
  cdt_gestion: boolean = false;
  tab_cdt_emp_doc: boolean = false;
  tab_cdt_trab: boolean = false;
  cdt_gestion_prl: boolean = false;
  // cdt_gestion_visitas: boolean = false;
  // cdt_gestion_emergencias: boolean = false;
  // cdt_gestion_simulacros: boolean = false;
  cdt_gestion_accidentes: boolean = false;
  cdt_gestion_accidentes_intro: boolean = false;
  cdt_gestion_accidentes_intro_imp: boolean = false;
  cdt_gestion_resumen_med_corr: boolean = false;
  //PRL
  prl_crea_fecha_fin: boolean = false;
  prl_crea_anexo: boolean = false;
  prl_crea_gestion: boolean = false;
  prl_crea_informe: boolean = false;
  prl_crea_inspeccion: boolean = false;
  prl_crea_libro: boolean = false;
  prl_crea_pee: boolean = false;
  prl_crea_pee_mp: boolean = false;
  prl_crea_reunion: boolean = false;
  prl_crea_visita: boolean = false;
  prl_edita_mc_fechas: boolean = false;
  prl_edita_cerrada: boolean = false;
  prl_ver_doc: boolean = false;
  prl_creacion: boolean = false;
  // Flags para duplicar
  prl_duplicar_pee_des_per: boolean = false;
  prl_duplicar_checks_pee_des_per: boolean = false;

  acc_doc_ver: boolean = false;
  acc_trab_doc_ver: boolean = false;
  acc_trab_investigacion_ver: boolean = false;
  acc_trab_investigacion_editar: boolean = false;
  acc_delta: boolean = false;


  // // @computedFrom("prl_crea_anexo", "prl_crea_gestion", "prl_crea_informe", "prl_crea_inspeccion")
  // prl_creacion(): boolean {
  //   let v = this.prl_crea_anexo ||
  //     this.prl_crea_gestion ||
  //     this.prl_crea_informe ||
  //     this.prl_crea_inspeccion ||
  //     this.prl_crea_libro ||
  //     this.prl_crea_pee ||
  //     this.prl_crea_pee_mp ||
  //     this.prl_crea_reunion ||
  //     this.prl_crea_visita ||
  //     this.prl_edita_mc_fechas ||
  //     this.prl_edita_cerrada ||
  //     this.prl_ver_doc;

  //   console.log("prl_creacion", v);
  //   return v;
  // }
}

var appKey = "pgs_web_5"
@singleton()
@autoinject
export default class AuthService {

  authData: AuthData;

  // As soon as the AuthService is created, we query local storage to
  // see if the login information has been stored. If so, we immediately
  // load it into the session object on the AuthService.
  constructor(public appSettings: AppSettings,
    private app: Aurelia,
    private router: Router,
    private httpClient: HttpService,
    private ea: EventAggregator) {

    this.authData = JSON.parse(localStorage[appKey] || null);
    //this.password = JSON.parse(localStorage[passwordToken] || null);

    this.ea.subscribe('auf:unauthorized', () => {
      console.log("AuthService => Detected unauthorized error");
      //this.logout();
    });

    this.httpClient.setAuthService(this);

    console.log("AuthService:", this.authData);
  }

  login(username, password): Promise<any> {
    return this.httpClient.put("login", { username: username, password: password })
      .then(result => {
        let response = result.response;
        let json = result.data;
        console.log("LOGIN:", result);

        if (!response.ok) {
          console.log("LOGIN REJECT:", json);
          this.emitLoginError(json);
          return;
        }

        this.authData = new AuthData();
        this.authData.session = json.session;

        this.getUserPermissions(username);

        // .catch(err => this.emitLoginError("Connection error"));
      }).catch(err => this.emitLoginError(err.message))
  }

  loginWithSession(username, session) {
    this.authData = new AuthData();
    this.authData.session = session;

    this.getUserPermissions(username)
  }

  logout() {
    if (this.authData) {
      this.httpClient.put("logout", {});
      this.ea.publish("auth:logout");
      console.log("AuthService logout");
      // Clear from localStorage
      localStorage[appKey] = null;

      // .. and from the session object
      this.authData = null;
    }

    if (this.appSettings.UseOpenID) {

      let openidurl = window.location.origin + "/openid/logout";
      if (environment.debug) {
        openidurl = "http://localhost/openid/logout";
      }

      window.location.replace(openidurl);
      // var httpClient: HttpClient = new HttpClient();
      // httpClient
      // .fetch('http://' + environment.basePath+"/openid/logout",
      //   {
      //     method: 'get',
      //     mode: 'cors',
      //   });
    }
    // .. and set root to login.
    // this.router.navigateToRoute("empresas");
    this.router.navigate('/', { replace: true, trigger: false });
    this.app.setRoot('public');
  }


  getAppConfiguration(): Promise<any> {
    return this.httpClient.get("config")
      .then(result => {
        let response = result.response;
        let json = result.data;
        console.log("Permisos:", result);

        if (!response.ok) {
          console.log("Permisos REJECT:", json);
          this.emitLoginError(json);
          return;
        }

        return json;
      });
  }

  getUserPermissions(username: string): Promise<any> {
    return this.httpClient.get("usuario_permisos")
      .then(result => {
        let response = result.response;
        let json = result.data;
        console.log("Permisos:", result);

        if (!response.ok) {
          console.log("Permisos REJECT:", json);
          this.emitLoginError(json);
          return;
        }

        this.authData.tabTrabajadoresActivo = json.tab_trabajadores;
        this.authData.tabMaquinasActivo = json.tab_maquinas;
        this.authData.tabCdtActivo = json.tab_cdt;
        this.authData.tabCdtDocActivo = json.tab_cdt_doc;
        this.authData.tabDocEmpresa = json.tab_doc_empresa;
        this.authData.descargarDoc = json.descargar_doc;
        this.authData.descargarMasivaDoc = json.descargar_masiva_doc;
        this.authData.subirDoc = json.subir_doc;
        this.authData.doc_cad_edit = json.doc_cad_edit;
        this.authData.modificarDoc = json.modificar_doc;
        this.authData.modificarFechasDoc = json.modificar_fechas_doc;
        this.authData.modificarDescDoc = json.modificar_desc_doc;
        this.authData.modificarAptoMedicoDoc = json.modificar_ap_med_doc;
        this.authData.modificarCalidadDoc = json.modificar_calidad_doc;
        this.authData.modificarTrab = json.modificar_trab;
        this.authData.altaTrab = json.alta_trab;
        this.authData.asignaTrabCdt = json.asigna_trab_cdt;
        this.authData.desasignaTrabCdt = json.desasigna_trab_cdt;
        this.authData.editar_trab_cdt = json.editar_trab_cdt;
        this.authData.regeneraDoc = json.regenera_doc;
        this.authData.validaDoc = json.valida_doc;
        this.authData.revisaDoc = json.revisa_doc;
        this.authData.subidosDoc = json.subidos_doc;
        this.authData.ver_caducados = json.ver_caducados;
        this.authData.cdt_gestion = json.cdt_gestion;
        this.authData.tab_cdt_emp_doc = json.tab_cdt_emp_doc;
        this.authData.tab_cdt_trab = json.tab_cdt_trab;
        this.authData.cdt_gestion_prl = json.cdt_gestion_prl;


        // this.authData.cdt_gestion_visitas = json.cdt_gestion_visitas;
        // this.authData.cdt_gestion_emergencias = json.cdt_gestion_emergencias;
        // this.authData.cdt_gestion_simulacros = json.cdt_gestion_simulacros;
        this.authData.cdt_gestion_accidentes = json.cdt_gestion_accidentes;
        this.authData.cdt_gestion_accidentes_intro = json.cdt_gestion_accidentes_intro;
        this.authData.cdt_gestion_accidentes_intro_imp = json.cdt_gestion_accidentes_intro_imp;
        this.authData.cdt_gestion_resumen_med_corr = json.cdt_gestion_resumen_med_corr;
        // PRL
        this.authData.prl_crea_fecha_fin = json.prl_crea_fecha_fin;
        this.authData.prl_crea_anexo = json.prl_crea_anexo;
        this.authData.prl_crea_gestion = json.prl_crea_gestion;
        this.authData.prl_crea_informe = json.prl_crea_informe;
        this.authData.prl_crea_inspeccion = json.prl_crea_inspeccion;
        this.authData.prl_crea_libro = json.prl_crea_libro;
        this.authData.prl_crea_pee = json.prl_crea_pee;
        this.authData.prl_crea_pee_mp = json.prl_crea_pee_mp;
        this.authData.prl_crea_reunion = json.prl_crea_reunion;
        this.authData.prl_crea_visita = json.prl_crea_visita;
        this.authData.prl_edita_mc_fechas = json.prl_edita_mc_fechas;
        this.authData.prl_edita_cerrada = json.prl_edita_cerrada;
        this.authData.prl_ver_doc = json.prl_ver_doc;

        this.authData.prl_creacion = this.authData.prl_crea_anexo || this.authData.prl_crea_gestion || this.authData.prl_crea_informe || this.authData.prl_crea_inspeccion || this.authData.prl_crea_libro || this.authData.prl_crea_pee || this.authData.prl_crea_pee_mp || this.authData.prl_crea_reunion || this.authData.prl_crea_visita || this.authData.prl_edita_mc_fechas || this.authData.prl_edita_cerrada || this.authData.prl_ver_doc;

        // Duplicar
        this.authData.prl_duplicar_pee_des_per = json.prl_duplicar_pee_des_per;
        this.authData.prl_duplicar_checks_pee_des_per = json.prl_duplicar_checks_pee_des_per;

        // Trab acc
        this.authData.acc_doc_ver = json.acc_doc_ver;
        this.authData.acc_trab_doc_ver = json.acc_trab_doc_ver;
        this.authData.acc_trab_investigacion_ver = json.acc_trab_investigacion_ver;
        this.authData.acc_trab_investigacion_editar = json.acc_trab_investigacion_editar;
        this.authData.acc_delta = json.acc_delta;


        // this.authData.isSuperUser = json.data.is_admin;
        // this.authData.inSuperGroup = json.data.in_supergroup;
        this.authData.username = username;

        // .. and to localStorage
        localStorage[appKey] = JSON.stringify(this.authData);

        // console.log("AuthService login:", this.authData);

        // .. and set root to app.
        this.router.navigate('/', { replace: true, trigger: false });

        // console.log("this.router.currentInstruction.config.name", this.router.currentInstruction.config.name)
        // this.router.navigateToRoute(
        //   this.router.currentInstruction.config.name, // current route name
        //   {}, // route parameters object
        //   { trigger: false, replace: true } // options
        // );

        this.app.setRoot('app');


      }).catch(err => this.emitLoginError(err.message))
  }

  getUsername(): string {
    if (this.authData) {
      return this.authData.username;
    }
    return null;
  }

  setSession(session: string) {
    console.log("AuthService setSession");
    if (!this.authData) {
      this.authData = new AuthData();
    }
    this.authData.session = session;
    this.authData.isSuperUser = false;
    this.authData.inSuperGroup = false;
    this.authData.username = "admin";
    localStorage[appKey] = JSON.stringify(this.authData);

    console.log("AuthService SetSession:", this.authData);
    return;
  }

  getSession(): string {
    if (this.authData) {
      return this.authData.session;
    }
    return null;
  }

  isSuperUser(): boolean {
    if (this.authData) {
      return this.authData.isSuperUser;
    }
    return false;
  }

  inSuperGroup(): boolean {
    if (this.authData) {
      return this.authData.inSuperGroup;
    }
    return false;
  }


  isAuthenticated(): boolean {
    return this.authData && this.authData.session !== null;
  }

  can(permission) {
    return true; // why not?
  }

  emitLoginError(error: string) {
    this.ea.publish("auth:login:error", error);
  }
}
